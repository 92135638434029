import * as React from "react";
import { Button, Container, Header, PreviousButton } from "./Elements";
import { useTranslation } from "react-i18next";

interface ResultProps {
  onAccept: () => void;
  onPrevious: () => void;
}

const Result = ({ onAccept, onPrevious }: ResultProps) => {
  const { t } = useTranslation("socialsorting");
  return (
    <Container>
      <Header>{t("save-answers")}</Header>
      <Button onClick={onAccept}>{t("accept")}</Button>
      <PreviousButton onClick={onPrevious}>{t("previous")}</PreviousButton>
    </Container>
  );
};

export default Result;
