import * as React from "react";
import Base from "../CustomStyleComponents/Base";
import { Text } from "../CustomStyleComponents/StyledComponents";
import StyledButton from "../CustomStyleComponents/Button";
import { useTranslation } from "react-i18next";

interface landingPageProps {
  onClick: () => void;
}

const SimpleLogin = (props: landingPageProps) => {
  const { t } = useTranslation("emojiii");
  const text = t("youre-in");
  const buttonText = t("donedone");

  return (
    <Base
      mainText={<Text>{text}</Text>}
      footerText={
        <StyledButton onClick={props.onClick}>{buttonText}</StyledButton>
      }
    />
  );
};

export default SimpleLogin;
