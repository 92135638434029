import { Tone } from "@smartphoneorchestra/live.js";
import { randomElement } from "./toucan/src/utils";

const notificationPlaybackRate = [
  0.793701, 0.890899, 1, 1.122462, 1.259921, 1.414214, 1.587401,
];

const notificationPlayer = new Tone.Player(
  "https://firebasestorage.googleapis.com/v0/b/emojiii-4acc2.appspot.com/o/samples%2FNotificationSound%2FiMessage.mp3?alt=media&token=48020720-cea4-4f06-9066-1b84b886e441"
).toDestination();
const dataSorting = new Tone.Player(
  "https://firebasestorage.googleapis.com/v0/b/emojiii-4acc2.appspot.com/o/samples%2FDataSorting%2FTest-DataSorting3.mp3?alt=media&token=d929c036-c382-4993-8e7b-6f3ea89d3f6b"
).toDestination();

const correctSamples = [
  new Tone.Player(
    "https://firebasestorage.googleapis.com/v0/b/emojiii-4acc2.appspot.com/o/samples%2FEmoGame%2FCorrect_Answer_1.mp3?alt=media&token=b85386ee-4b9b-45a0-982b-e81bad5e536a"
  ).toDestination(),
  new Tone.Player(
    "https://firebasestorage.googleapis.com/v0/b/emojiii-4acc2.appspot.com/o/samples%2FEmoGame%2FCorrect_Answer_2.mp3?alt=media&token=4ed84754-c079-4aa4-b983-f9e219641bee"
  ).toDestination(),
  new Tone.Player(
    "https://firebasestorage.googleapis.com/v0/b/emojiii-4acc2.appspot.com/o/samples%2FEmoGame%2FCorrect_Answer_3.mp3?alt=media&token=bf34e551-afc3-4994-870a-daf9ecac932a"
  ).toDestination(),
];

const wrongSamples = [
  new Tone.Player(
    "https://firebasestorage.googleapis.com/v0/b/emojiii-4acc2.appspot.com/o/samples%2FEmoGame%2FWrong_Answer_1.mp3?alt=media&token=dce3b9d4-90d9-4788-a4cb-9fe2b610f6fb"
  ).toDestination(),
  new Tone.Player(
    "https://firebasestorage.googleapis.com/v0/b/emojiii-4acc2.appspot.com/o/samples%2FEmoGame%2FWrong_Answer_2.mp3?alt=media&token=5f3fb6e5-975b-4487-b569-e0c346e44985"
  ).toDestination(),
  new Tone.Player(
    "https://firebasestorage.googleapis.com/v0/b/emojiii-4acc2.appspot.com/o/samples%2FEmoGame%2FWrong_Answer_3.mp3?alt=media&token=0d321f53-849a-44f9-9648-3b88e59a6536"
  ).toDestination(),
  new Tone.Player(
    "https://firebasestorage.googleapis.com/v0/b/emojiii-4acc2.appspot.com/o/samples%2FEmoGame%2FWrong_Answer_4.mp3?alt=media&token=a5e183eb-f5c3-4469-b15b-869881dfa0bb"
  ).toDestination(),
  new Tone.Player(
    "https://firebasestorage.googleapis.com/v0/b/emojiii-4acc2.appspot.com/o/samples%2FEmoGame%2FWrong_Answer_5.mp3?alt=media&token=cb26f45f-b593-4974-bb22-4f009a52ca4b"
  ).toDestination(),
];

const happyButton = new Tone.Player(
  "https://firebasestorage.googleapis.com/v0/b/emojiii-4acc2.appspot.com/o/samples%2FEmoGame%2FHappy_Sound.mp3?alt=media&token=8371862b-ba16-40f0-ac61-e9b9840c61b6"
).toDestination();

const notificationPlayers = [
  new Tone.Player(
    "https://firebasestorage.googleapis.com/v0/b/emojiii-4acc2.appspot.com/o/samples%2F1-SlideSounds%2F1_Slide_Sound_1.mp3?alt=media&token=881d0003-e740-48b5-b74f-cca84eb2db25&_gl=1*dqmpgo*_ga*MTgwMzI2NDI3My4xNjI0OTczNzQx*_ga_CW55HF8NVT*MTY4NjQyMjkwMi44NS4xLjE2ODY0MjQzNzIuMC4wLjA."
  ).toDestination(),
  new Tone.Player(
    "https://firebasestorage.googleapis.com/v0/b/emojiii-4acc2.appspot.com/o/samples%2F1-SlideSounds%2F1_Slide_Sound_2.mp3?alt=media&token=699b847e-dc4e-431f-98ed-d146a450a798&_gl=1*27jzq9*_ga*MTgwMzI2NDI3My4xNjI0OTczNzQx*_ga_CW55HF8NVT*MTY4NjQyMjkwMi44NS4xLjE2ODY0MjQ1NzYuMC4wLjA."
  ).toDestination(),
  new Tone.Player(
    "https://firebasestorage.googleapis.com/v0/b/emojiii-4acc2.appspot.com/o/samples%2F1-SlideSounds%2F1_Slide_Sound_3.mp3?alt=media&token=ea1e41c9-84fa-443b-91ea-e198fac545de&_gl=1*j91mo2*_ga*MTgwMzI2NDI3My4xNjI0OTczNzQx*_ga_CW55HF8NVT*MTY4NjQyMjkwMi44NS4xLjE2ODY0MjQ0MTQuMC4wLjA."
  ).toDestination(),
  new Tone.Player(
    "https://firebasestorage.googleapis.com/v0/b/emojiii-4acc2.appspot.com/o/samples%2F1-SlideSounds%2F1_Slide_Sound_4.mp3?alt=media&token=4f4f58b3-f115-4d50-bc69-c854c956c61b&_gl=1*hy0e09*_ga*MTgwMzI2NDI3My4xNjI0OTczNzQx*_ga_CW55HF8NVT*MTY4NjQyMjkwMi44NS4xLjE2ODY0MjQ0MjkuMC4wLjA."
  ).toDestination(),
  new Tone.Player(
    "https://firebasestorage.googleapis.com/v0/b/emojiii-4acc2.appspot.com/o/samples%2F1-SlideSounds%2F1_Slide_Sound_5.mp3?alt=media&token=25788599-afaf-4f43-99e0-533d35bb247c&_gl=1*m3l9pe*_ga*MTgwMzI2NDI3My4xNjI0OTczNzQx*_ga_CW55HF8NVT*MTY4NjQyMjkwMi44NS4xLjE2ODY0MjQ0NDIuMC4wLjA."
  ).toDestination(),
  new Tone.Player(
    "https://firebasestorage.googleapis.com/v0/b/emojiii-4acc2.appspot.com/o/samples%2F1-SlideSounds%2F1_Slide_Sound_6.mp3?alt=media&token=449214a9-91e0-44c5-b24b-a4b938b02efd&_gl=1*1j7ovup*_ga*MTgwMzI2NDI3My4xNjI0OTczNzQx*_ga_CW55HF8NVT*MTY4NjQyMjkwMi44NS4xLjE2ODY0MjQ0NTYuMC4wLjA."
  ).toDestination(),
  new Tone.Player(
    "https://firebasestorage.googleapis.com/v0/b/emojiii-4acc2.appspot.com/o/samples%2F1-SlideSounds%2F1_Slide_Sound_7.mp3?alt=media&token=fbeaf73c-2120-4d0d-952b-a078175b2252&_gl=1*14ovg7d*_ga*MTgwMzI2NDI3My4xNjI0OTczNzQx*_ga_CW55HF8NVT*MTY4NjQyMjkwMi44NS4xLjE2ODY0MjQ0NjMuMC4wLjA."
  ).toDestination(),
  new Tone.Player(
    "https://firebasestorage.googleapis.com/v0/b/emojiii-4acc2.appspot.com/o/samples%2F1-SlideSounds%2F1_Slide_Sound_8.mp3?alt=media&token=b2039300-158d-479b-b459-e20596d8150a&_gl=1*1vm1hwl*_ga*MTgwMzI2NDI3My4xNjI0OTczNzQx*_ga_CW55HF8NVT*MTY4NjQyMjkwMi44NS4xLjE2ODY0MjQ0ODYuMC4wLjA."
  ).toDestination(),
  new Tone.Player(
    "https://firebasestorage.googleapis.com/v0/b/emojiii-4acc2.appspot.com/o/samples%2F1-SlideSounds%2F1_Slide_Sound_9.mp3?alt=media&token=6918ffb1-58c8-41ef-9b14-100eec93df77&_gl=1*9l02cf*_ga*MTgwMzI2NDI3My4xNjI0OTczNzQx*_ga_CW55HF8NVT*MTY4NjQyMjkwMi44NS4xLjE2ODY0MjQ0OTcuMC4wLjA."
  ).toDestination(),
  new Tone.Player(
    "https://firebasestorage.googleapis.com/v0/b/emojiii-4acc2.appspot.com/o/samples%2F1-SlideSounds%2F1_Slide_Sound_10.mp3?alt=media&token=5190cc37-0a39-4652-8a72-54e2ec5bc34c&_gl=1*guf8j6*_ga*MTgwMzI2NDI3My4xNjI0OTczNzQx*_ga_CW55HF8NVT*MTY4NjQyMjkwMi44NS4xLjE2ODY0MjQ1MTAuMC4wLjA."
  ).toDestination(),
];

function audioChecks(player: Tone.Player) {
  if (Tone.context.state !== "running" || !player.loaded) {
    return;
  }
  if (player.state === "started") {
    player.restart(Tone.now() + 0.1);
  } else {
    player.start(Tone.now() + 0.1);
  }
}

export const buttonPlayers = [
  new Tone.Player(
    "https://firebasestorage.googleapis.com/v0/b/emojiii-4acc2.appspot.com/o/samples%2FEmoGame%2FWrong_Answer_1.mp3?alt=media&token=dce3b9d4-90d9-4788-a4cb-9fe2b610f6fb"
  ).toDestination(),
  new Tone.Player(
    "https://firebasestorage.googleapis.com/v0/b/emojiii-4acc2.appspot.com/o/samples%2FEmoGame%2FWrong_Answer_1.mp3?alt=media&token=dce3b9d4-90d9-4788-a4cb-9fe2b610f6fb"
  ).toDestination(),
  new Tone.Player(
    "https://firebasestorage.googleapis.com/v0/b/emojiii-4acc2.appspot.com/o/samples%2FEmoGame%2FWrong_Answer_1.mp3?alt=media&token=dce3b9d4-90d9-4788-a4cb-9fe2b610f6fb"
  ).toDestination(),
  new Tone.Player(
    "https://firebasestorage.googleapis.com/v0/b/emojiii-4acc2.appspot.com/o/samples%2FEmoGame%2FWrong_Answer_1.mp3?alt=media&token=dce3b9d4-90d9-4788-a4cb-9fe2b610f6fb"
  ).toDestination(),
];

export function playNotification() {
  notificationPlayer.playbackRate = randomElement(notificationPlaybackRate);
  audioChecks(notificationPlayer);
}

export function playCorrect() {
  const randomPlayer = randomElement(correctSamples);
  if (randomPlayer.buffer.loaded) {
    randomPlayer.start(Tone.now() + 0.01);
  } else {
    randomPlayer.autostart = true;
  }
}

export function playWrong() {
  const randomPlayer = randomElement(wrongSamples);
  if (randomPlayer.buffer.loaded) {
    randomPlayer.start(Tone.now() + 0.01);
  } else {
    randomPlayer.autostart = true;
  }
}

export function playHappy() {
  happyButton.playbackRate = randomElement(notificationPlaybackRate);
  happyButton.start(Tone.now() + 0.01);
}

export function playDataSorting() {
  const dataPlaybackRate = [1.0, 1.25, 1.5, 1.75, 2];
  dataSorting.playbackRate = randomElement(dataPlaybackRate);
  dataSorting.loop = true;
  const reverse = Boolean(Math.round(Math.random()));
  dataSorting.reverse = reverse;
  if (dataSorting.buffer.loaded === true) {
    dataSorting.start(Tone.now() + 0.01);
  } else {
    dataSorting.autostart = true;
  }
}

export function stopDataSorting() {
  dataSorting.stop();
}

export function playRandomNotificationSound() {
  const randomPlayer = randomElement(notificationPlayers);
  if (randomPlayer.buffer.loaded) {
    randomPlayer.start(Tone.now() + 0.01);
  } else {
    randomPlayer.autostart = true;
  }
}

export function playCustomSample(sample: string, vol?: number) {
  const player = new Tone.Player(sample).toDestination();
  player.volume.value = vol ? vol : -12;
  if (player.buffer.loaded) {
    player.start(Tone.now());
  } else {
    player.autostart = true;
  }
  // audioChecks(player);
}
