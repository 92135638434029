import * as React from "react";
import { Update } from "./toucan/src/firebase/mutations";
import { useTranslation } from "react-i18next";
import {
  Text,
  UserEmojiSelect,
} from "./CustomStyleComponents/StyledComponents";
import Base from "./CustomStyleComponents/Base";
import { useInterpolatedString } from "./toucan/src/interpolate";
import StyledButton from "./CustomStyleComponents/Button";

const EmojiPicker = () => {
  const { t } = useTranslation("emojiii");
  const transText = t("favorite");
  const text = useInterpolatedString(transText);
  const emojiOptions = t("emojioptions").split(" ");

  const [favEmoji, setFavEmoji] = React.useState("");

  const nextEnabled = favEmoji !== "";

  const emojiChangeEvent = (e: React.ChangeEvent<HTMLSelectElement>) =>
    setFavEmoji(e.currentTarget.value);

  const saveEmoji = () => {
    const update = new Update();
    const interfaceName = "show_emoji";
    update.updateUser({ emoji: favEmoji, interface: interfaceName });
    update.execute();
  };

  return (
    <Base
      mainText={
        <React.Fragment>
          <Text>{text}</Text>
          <div>
            <UserEmojiSelect value={favEmoji} onChange={emojiChangeEvent}>
              <option disabled value="">
                {"Click me!"}
              </option>

              {emojiOptions.map((option, index) => {
                return (
                  <option value={option} key={index}>
                    {option}
                  </option>
                );
              })}
            </UserEmojiSelect>
          </div>
        </React.Fragment>
      }
      footerText={
        <StyledButton disabled={!nextEnabled} onClick={() => saveEmoji()}>
          {"Submit!"}
        </StyledButton>
      }
    />
  );
};

export default EmojiPicker;
