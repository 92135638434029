import styled from "styled-components";

export const Button = styled.button`
  font-size: 1rem;
  background: white;
  border-radius: 5px;
  color: #484848;
  border: 1px solid #484848;
  padding: 1rem;
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  width: 90%;
  height: 100%;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: auto auto;
`;

export const Header = styled.h1`
  font-size: 1.5rem;
  text-align: center;
`;

export const PreviousButton = styled(Button)`
  color: #c8c8c8;
  border: 1px solid #c8c8c8;
`;
