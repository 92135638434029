import * as React from "react";
import GenericLayout from "./toucan/src/components/GenericLayout";
import { useTranslation } from "react-i18next";
import InterfaceManager from "./toucan/src/interfaces/manager";

interface TextSliderProps {
  texts: string[];
}

const TextSlider = ({ texts }: TextSliderProps) => {
  const { t } = useTranslation("socialsorting");
  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    InterfaceManager.notifyComponentChanged("textslider");
  }, [texts]);

  return (
    <GenericLayout
      mainText={
        <div>
          <p>
            {index + 1} of {texts.length}
          </p>
          <p>{t(texts[index])}</p>
          {
            <button
              className={"btn btn-outline-secondary"}
              onClick={() => setIndex((index + 1) % texts.length)}
            >
              {t("next")}
            </button>
          }
        </div>
      }
    />
  );
};

export default TextSlider;
