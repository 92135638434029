import * as React from "react";
import {
  TextBiggerEmoji,
  ButtonContainer,
  ReplacedEmoji,
} from "../CustomStyleComponents/StyledComponents";
import Base from "../CustomStyleComponents/Base";
import { replaceEmojiWithLocalImg, useReplaceEmojis } from "../replaceEmojis";

interface ButtonOption {
  emoji: string;
}

interface SoundButtonsProps {
  text: string;
  options: ButtonOption[];
  onSelect: (bool: boolean, selected: number) => void;
}

const SoundButtons = (props: SoundButtonsProps) => {
  const [selected, setSelected] = React.useState(4);
  const [selectedColor, setSelectedColor] = React.useState("white");
  const [clicked, setClicked] = React.useState(false);

  const replacedText = useReplaceEmojis(props.text);

  const onSelectCollection = (index: number) => {
    setSelected(index);
    setSelectedColor("limegreen");
    props.onSelect(true, index);
  };

  return (
    <Base
      mainText={
        <React.Fragment>
          <div>
            <ButtonContainer>
              {props.options.map((option, index) => (
                <div
                  style={{
                    backgroundColor:
                      index === selected ? selectedColor : "white",
                    pointerEvents: clicked ? "none" : "auto",
                  }}
                  key={index}
                  onClick={() => {
                    setClicked(true);
                    onSelectCollection(index);
                  }}
                >
                  {
                    <ReplacedEmoji size="6rem">
                      {replaceEmojiWithLocalImg(option.emoji)}
                    </ReplacedEmoji>
                  }
                </div>
              ))}
            </ButtonContainer>
          </div>
        </React.Fragment>
      }
      footerText={
        <TextBiggerEmoji size="1.75rem">{replacedText}</TextBiggerEmoji>
      }
    />
  );
};

export default SoundButtons;
