import * as React from "react";
import emojiRegex from "emoji-regex";
import parseToHTML from "html-react-parser";

export function useReplaceEmojis(str: string, quality?: string) {
  const checkedQuality = quality || 72;
  const regex = emojiRegex();
  const emojiArr = str.match(regex) || [];

  const replacements = emojiArr.map((emoji) => {
    const unicode = emoji.codePointAt(0)?.toString(16);
    const fileName = `emoji_u${unicode}.png`;
    const url = `https://firebasestorage.googleapis.com/v0/b/emojiii-4acc2.appspot.com/o/emojis%2F${checkedQuality}%2F${encodeURIComponent(
      fileName
    )}?alt=media&token`;
    const img = `<img class='emojified' alt=${emoji} src=${url}/>`;
    return img;
  });

  const newStr = replacements.reduce<string>((pv, cv, idx) => {
    return pv.replace(emojiArr[idx], cv);
  }, str);

  return parseToHTML(newStr);
}

export function replaceEmojiWithLocalImg(str: string) {
  const regex = emojiRegex();
  const emojiArr = str.match(regex) || [];

  //if duplicate or emoji with complicated unicode use custom keywording
  if (emojiArr.length === 0 && str.includes("emoji_")) {
    const url = `${process.env.PUBLIC_URL}/emojis/${str}.png`;
    return <img className="emojified" alt={str} src={url} />;
  }

  const replacements = emojiArr.map((emoji, index) => {
    const unicode = emoji.codePointAt(0)?.toString(16);
    const url = `${process.env.PUBLIC_URL}/emojis/emoji_u${unicode}.png`;
    return <img key={index} className="emojified" alt={emoji} src={url} />;
  });

  return replacements;
}
