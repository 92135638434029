import * as React from "react";
import { useFirebaseUserState } from "../toucan/src/firebase/hooks";
import SimpleLogin from "./SimpleLogin";
import OnBoarding from "./OnBoarding";

const Login = () => {
  const [participating, setParticipating] =
    useFirebaseUserState<boolean>("participating");

  const saveProfile = () => {
    setParticipating(true);
  };

  return participating ? (
    <OnBoarding></OnBoarding>
  ) : (
    <SimpleLogin onClick={saveProfile}></SimpleLogin>
  );
};

export default Login;
