import * as React from "react";
import GenericLayout from "./toucan/src/components/GenericLayout";
import { useInterpolatedString } from "./toucan/src/interpolate";
import InterfaceManager from "./toucan/src/interfaces/manager";

interface ActionProps {
  action: string;
  text: string;
}

const Action = (props: ActionProps) => {
  const action = useInterpolatedString(props.action);
  const text = useInterpolatedString(props.text);

  React.useEffect(() => {
    InterfaceManager.notifyComponentChanged("action");
  }, [action, text]);

  return (
    <GenericLayout
      contentClass="text"
      mainText={
        <React.Fragment>
          <h1>{action}</h1>
          <h1 className="actionValue">{text}</h1>
        </React.Fragment>
      }
    />
  );
};

export default Action;
