import * as React from "react";
import GenericLayout from "./toucan/src/components/GenericLayout";
import styled from "styled-components";
import { useInterpolatedString } from "./toucan/src/interpolate";
import InterfaceManager from "./toucan/src/interfaces/manager";

interface ITextProps {
  text: string;
}

const Header = styled.h1`
  font-size: 10rem;
`;

const Number = (props: ITextProps) => {
  const text = useInterpolatedString(props.text);
  React.useEffect(() => {
    InterfaceManager.notifyComponentChanged("text");
  }, [text]);
  return (
    <GenericLayout
      contentClass="text"
      mainText={
        <div>
          <Header>{text}</Header>
        </div>
      }
    />
  );
};

export default Number;
