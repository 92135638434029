import * as React from "react";
import { useTranslation } from "react-i18next";
import { useInterpolatedString } from "../toucan/src/interpolate";
import SoundButtons from "./SoundButtons";
import SilenceText from "./SilenceText";
import { Tone } from "@smartphoneorchestra/live.js";
import { buttonPlayers, playRandomNotificationSound } from "../sound";

interface sampleUrl {
  url: string;
}

interface ButtonOption {
  emoji: string;
}

interface SoundButtonsGateProps {
  question: string;
  silenceText: string;
  emojiOptions: ButtonOption[];
  sampleUrls: sampleUrl[];
}

const SoundButtonsGate = (props: SoundButtonsGateProps) => {
  const [selectedBuffer, setSelectedBuffer] = React.useState(4);
  const [gateSwitch, setGateSwitch] = React.useState(false);
  const [selectedEmoji, setSelectedEmoji] = React.useState("");

  const { t } = useTranslation("emojiii");
  const transQuestion = t(props.question);
  const question = useInterpolatedString(transQuestion);

  const transText = t(props.silenceText);
  const text = useInterpolatedString(transText);

  const urls = props.sampleUrls;
  const buttonOptions = props.emojiOptions;

  const onSelect = (bool: boolean, selected: number) => {
    setSelectedBuffer(selected);
    setSelectedEmoji(buttonOptions[selected].emoji);
    setTimeout(() => {
      setGateSwitch(bool);
    }, 1000);
  };

  React.useEffect(() => {
    //reset state
    setGateSwitch(false);
    setSelectedBuffer(4);

    //init the players
    buttonPlayers.forEach((player, index) => {
      player.load(urls[index].url);
      player.loop = true;
    });

    return function cleanup() {
      buttonPlayers.forEach((player) => {
        player.stop();
      });
    };
  }, [question, props.sampleUrls]);

  React.useEffect(() => {
    if (selectedBuffer !== 4) {
      const player = buttonPlayers[selectedBuffer];
      if (player.buffer.loaded === true) {
        player.start(Tone.now() + 0.01);
      } else {
        player.autostart = true;
      }

      return function cleanup() {
        player.stop();
      };
    }
  }, [selectedBuffer]);

  React.useEffect(() => {
    playRandomNotificationSound();
  }, []);

  return (
    <div>
      {gateSwitch ? (
        <SilenceText {...props} text={text} emoji={selectedEmoji}></SilenceText>
      ) : (
        <SoundButtons
          text={question}
          options={buttonOptions}
          onSelect={onSelect}
        ></SoundButtons>
      )}
    </div>
  );
};

export default SoundButtonsGate;
