import * as React from "react";
import { useWriteOnlyFirebaseUserState } from "./toucan/src/firebase/hooks";
import { useInterpolatedString } from "./toucan/src/interpolate";
import { useTranslation } from "react-i18next";
import InterfaceManager from "./toucan/src/interfaces/manager";
import {
  Text,
  ButtonContainer,
} from "./CustomStyleComponents/StyledComponents";
import Base from "./CustomStyleComponents/Base";
import StyledButton from "./CustomStyleComponents/Button";

interface GateProps {
  text: string;
  options: ButtonOption[];
  nextInterface: string;
}

interface ButtonOption {
  text: string;
  nextInterface: string;
}

const ButtonGate = (props: GateProps) => {
  const { t } = useTranslation("emojiii");
  const transtext = t(props.text);
  const text = useInterpolatedString(transtext);

  // const nextInterface = useInterpolatedString(props.nextInterface);
  const setInterface = useWriteOnlyFirebaseUserState<string>("interface");

  const onClick = (buttonIndex: number) => {
    const nextInterface = props.options[buttonIndex].nextInterface;
    if (nextInterface) {
      setInterface(nextInterface);
    }
  };

  React.useEffect(() => {
    InterfaceManager.notifyComponentChanged("buttongate");
  }, [text]);

  return (
    <Base
      mainText={
        <React.Fragment>
          <Text size="1.5rem">{text}</Text>
          <div>
            <ButtonContainer style={{ gap: "3.5em", flexDirection: "column" }}>
              {props.options.map((option, index) => (
                <StyledButton
                  key={index}
                  onClick={() => {
                    onClick(index);
                  }}
                >
                  {option.text}
                </StyledButton>
              ))}
            </ButtonContainer>
          </div>
        </React.Fragment>
      }
    />
  );
};

export default ButtonGate;
