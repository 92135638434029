import * as React from "react";
import { QRCodeSVG } from "qrcode.react";

interface QrCodeFromUserIdProps {
  userId: string;
}

const QRCodeFromUserId = (props: QrCodeFromUserIdProps) => {
  const userId = props.userId;
  return <QRCodeSVG value={userId} size={200} />;
};

export default QRCodeFromUserId;
