import * as React from "react";
import ComponentRenderer from "./toucan/src/interfaces";
import { useInterfaceManager } from "./Managers/InterfaceManager";
import { useFirebaseUserState } from "./toucan/src/firebase/hooks";
import { UserData } from "./types";
import Login from "./Login";

const App = () => {
  const [user] = useFirebaseUserState<UserData>("/");
  const currentInterface = useInterfaceManager();
  const participating = user?.participating;

  React.useEffect(() => {
    document.body.className = currentInterface || "";
  }, [currentInterface]);

  // if (!user) {
  //   return <Loading />;
  // }

  if (participating === undefined || currentInterface === null) {
    return <Login />;
  }

  return <ComponentRenderer interface={currentInterface} />;
};

export default App;
