import * as React from "react";
import {
  useWriteOnlyFirebaseUserState,
  useFirebaseUserState,
} from "../toucan/src/firebase/hooks";
import { useTranslation } from "react-i18next";
import { Text, ReplacedEmoji } from "../CustomStyleComponents/StyledComponents";
import Base from "../CustomStyleComponents/Base";
import { useReplaceEmojis } from "../replaceEmojis";

interface EmoTextProps {
  gameIndex: number;
  options: string[][];
  answers: number[];
}

const EmoText = (props: EmoTextProps) => {
  const [correctAnswer, setCorrectAnswer] = React.useState(false);

  const gameIndex = props.gameIndex;
  const roundOptions = props.options[gameIndex];
  const answer = props.answers[gameIndex];
  const emoji = roundOptions[answer];

  const imgEmoji = useReplaceEmojis(emoji, "512");

  const { t } = useTranslation("emojiii");
  const text = correctAnswer ? t("well-done") : t("mimicker-instruction");

  const [backgroundColor, setBackgroundColor] =
    useFirebaseUserState<string>("backgroundColor");

  if (!backgroundColor) setBackgroundColor("white");

  const setOwnBackgroundColor =
    useWriteOnlyFirebaseUserState<string>("backgroundColor");

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (backgroundColor === "red") {
        setOwnBackgroundColor("white");
      }
    }, 750);

    if (backgroundColor === "limegreen") {
      setCorrectAnswer(true);
    } else if (backgroundColor === "white") {
      clearTimeout(timer);
    }

    return () => clearTimeout(timer);
  }, [backgroundColor]);

  return (
    <div style={{ backgroundColor: backgroundColor || "white" }}>
      <Base
        mainText={<ReplacedEmoji>{imgEmoji}</ReplacedEmoji>}
        footerText={<Text>{text}</Text>}
      />
    </div>
  );
};

export default EmoText;
