import * as React from "react";
import FirebaseImpl from "../toucan/src/firebase";
import { playDataSorting, stopDataSorting } from "../sound";
import { useTranslation } from "react-i18next";
import { Update } from "../toucan/src/firebase/mutations";
import { useInterpolatedString } from "../toucan/src/interpolate";

import { Text } from "../CustomStyleComponents/StyledComponents";
import Base from "../CustomStyleComponents/Base";
import StyledButton from "../CustomStyleComponents/Button";

import { QrReader } from "react-qr-reader";
import QRCodeFromUserId from "./QrCode";
import QRStateButton from "./QrStateButton";

import { useWriteOnlyFirebaseUserState } from "../toucan/src/firebase/hooks";

interface QrCodeProps {
  succesInterface: string;
}

const QrCode = (props: QrCodeProps) => {
  const { t } = useTranslation("emojiii");
  const [isScanner, setIsScanner] = React.useState(false);
  const [data, setData] = React.useState("No result");
  const setInterface = useWriteOnlyFirebaseUserState<string>("interface");

  React.useEffect(() => {
    playDataSorting();

    return function cleanup() {
      stopDataSorting();
    };
  }, []);

  React.useEffect(() => {
    if (data !== "No result") {
      updateUsers();
    }
  }, [data]);

  const userId = FirebaseImpl.uuid();
  const infoText = isScanner ? "doesnt-work" : "QRcode";
  const nextInterface = useInterpolatedString(props.succesInterface);

  const goBack = () => {
    setInterface("find_your_partner_gate");
  };

  const updateUsers = async () => {
    const update = new Update();
    update.updateUser({
      interface: nextInterface,
      otherName: data,
      gameState: "controller",
    });
    update.updateKey(`users/${data}`, {
      interface: nextInterface,
      otherName: userId,
      gameState: "actor",
    });
    update.execute();
  };

  const qrMode = (text: string) => {
    if (isScanner) {
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          <QrReader
            containerStyle={{
              width: "200%",
            }}
            constraints={{ facingMode: "environment" }}
            onResult={(result, error) => {
              if (!!result) {
                const qrData = result.getText();
                setData(qrData);
              }
              if (!!error) {
                console.info(error);
              }
            }}
          />
          <Text>{text}</Text>
        </div>
      );
    } else {
      return (
        <div>
          <Text style={{ margin: "20px" }}>{text}</Text>
          <QRCodeFromUserId userId={userId} />
        </div>
      );
    }
  };

  return (
    <Base
      showLogo={false}
      mainText={qrMode(t(infoText))}
      footerText={
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexDirection: "column",
            gap: "40px",
          }}
        >
          <QRStateButton qrState={isScanner} setQRState={setIsScanner} />
          {!isScanner ? (
            <StyledButton onClick={goBack}>Go back to emoji</StyledButton>
          ) : null}
        </div>
      }
    />
  );
};

export default QrCode;
