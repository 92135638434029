import * as React from "react";
import styled from "styled-components";
import InterfaceManager from "./toucan/src/interfaces/manager";

interface ImageProps {
  url: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const Image = ({ url }: ImageProps) => {
  React.useEffect(() => {
    InterfaceManager.notifyComponentChanged("image");
  }, [url]);

  if (!url) {
    return null;
  }
  return (
    <Container>
      <img src={url} alt="dynamic" />
    </Container>
  );
};

export default Image;
