import * as React from "react";
import Hearts from "./Hearts";
import { playCorrect, playWrong, playHappy } from "../sound";
import { useTranslation } from "react-i18next";
import {
  Text,
  ButtonContainer,
  HeartContainer,
  ReplacedEmoji,
} from "../CustomStyleComponents/StyledComponents";
import { Update } from "../toucan/src/firebase/mutations";
import Base from "../CustomStyleComponents/Base";
import { useReplaceEmojis } from "../replaceEmojis";

interface ButtonsProps {
  gameIndex: number;
  options: string[][];
  answers: number[];
  faults: number;
  maxFaults: number;
  showHearts: boolean;
  otherId: string;
  onNext: () => void;
  handleFaultOption: () => void;
}

const EmoButtons = (props: ButtonsProps) => {
  const { t } = useTranslation("emojiii");

  const [selected, setSelected] = React.useState(0);
  const [selectedColor, setSelectedColor] = React.useState("white");
  const [correctAnswerBool, setcorrectAnswerBool] = React.useState(false);
  const [roundOptions, setRoundOptions] = React.useState<JSX.Element[]>([]);

  const gameIndex = props.gameIndex;
  const answer = props.answers[gameIndex];

  React.useEffect(() => {
    const roundImgOptions = props.options[gameIndex].map((option) => {
      const imgEmojies = (
        <ReplacedEmoji size="6rem">
          {useReplaceEmojis(option, "128")}
        </ReplacedEmoji>
      );
      return imgEmojies;
    });

    setRoundOptions(roundImgOptions);
  }, [gameIndex]);

  const onNextButton = () => {
    playHappy();
    props.onNext();
  };

  const mainText = () => {
    const text = correctAnswerBool ? "awesome-word" : "guesser-instruction";
    return <Text>{t(text)}</Text>;
  };

  const footerView = () => {
    if (props.showHearts) {
      return (
        <HeartContainer>{Hearts(props.faults, props.maxFaults)}</HeartContainer>
      );
    } else {
      return;
    }
  };

  const setOtherBackgroundColor = (color: string) => {
    const update = new Update();
    update.updateKey(`users/${props.otherId}`, {
      backgroundColor: color,
    });
    update.execute();
  };

  return (
    <Base
      showLogo={false}
      mainText={
        <React.Fragment>
          {mainText()}
          <div>
            <ButtonContainer>
              {roundOptions.map((option, index) => (
                <div
                  style={{
                    backgroundColor:
                      index === selected ? selectedColor : "white",
                    pointerEvents: correctAnswerBool ? "none" : "auto",
                  }}
                  key={index}
                  onClick={() => {
                    setSelected(index);
                    if (index !== answer) {
                      //wrong
                      setSelectedColor("red");
                      setcorrectAnswerBool(false);
                      setOtherBackgroundColor("red");
                      props.handleFaultOption();
                      playWrong();
                    } else {
                      //right
                      setSelectedColor("limegreen");
                      setcorrectAnswerBool(true);
                      setOtherBackgroundColor("limegreen");
                      playCorrect();
                      setTimeout(() => {
                        onNextButton();
                      }, 2000);
                    }
                  }}
                >
                  {option}
                </div>
              ))}
            </ButtonContainer>
          </div>
        </React.Fragment>
      }
      footerText={footerView()}
    />
  );
};

export default EmoButtons;
