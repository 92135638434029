import * as React from "react";
import { useInterpolatedString } from "./toucan/src/interpolate";
import { playRandomNotificationSound } from "./sound";
import { useTranslation } from "react-i18next";
import { Text, ReplacedEmoji } from "./CustomStyleComponents/StyledComponents";
import Base, { ISharedBaseProps } from "./CustomStyleComponents/Base";
import { useReplaceEmojis } from "./replaceEmojis";

interface ITextEmoticonProps extends ISharedBaseProps {
  text: string;
  emoticon: string;
  emoticonSize?: string;
  textSize?: string;
  swap?: boolean;
}

const EmoticonTextFetch = (props: ITextEmoticonProps) => {
  const { t } = useTranslation("emojiii");
  const transText = t(props.text);
  const text = useInterpolatedString(transText);
  const textSize = useInterpolatedString(props.textSize || "1.75rem");
  const isSwap = props.swap ? true : false;

  const transEmoticon = t(props.emoticon);
  const emoticon = useInterpolatedString(transEmoticon);
  const checkedEmo = emoticon === "undefined" ? "❓" : emoticon;
  const emoSize = useInterpolatedString(props.emoticonSize || "10rem");

  const replacedEmo = useReplaceEmojis(checkedEmo, "512");

  React.useEffect(() => {
    //prevent notification sample being triggered when there is a custom sample
    if (!props.sample) {
      playRandomNotificationSound();
    }
  }, [text, emoticon]);

  const swapper = (swapBool: boolean) => {
    return swapBool ? (
      <React.Fragment>
        <ReplacedEmoji size={emoSize}>{replacedEmo}</ReplacedEmoji>
      </React.Fragment>
    ) : (
      <Text size={textSize}>{text}</Text>
    );
  };

  return (
    <Base {...props} mainText={swapper(!isSwap)} footerText={swapper(isSwap)} />
  );
};

export default EmoticonTextFetch;
