import * as React from "react";
import Base from "../CustomStyleComponents/Base";
import { Text, ReplacedEmoji } from "../CustomStyleComponents/StyledComponents";
import { replaceEmojiWithLocalImg, useReplaceEmojis } from "../replaceEmojis";

interface SilenceTextProps {
  text: string;
  emoji: string;
}

const SilenceText = (props: SilenceTextProps) => {
  const text = props.text;
  const emoji = props.emoji;

  const replacedText = useReplaceEmojis(text, "128");

  return (
    <Base
      mainText={
        <ReplacedEmoji>{replaceEmojiWithLocalImg(emoji)}</ReplacedEmoji>
      }
      footerText={<Text {...props}>{replacedText}</Text>}
    />
  );
};

export default SilenceText;
