import * as React from "react";
import {
  Text,
  ButtonContainer,
  ReplacedEmoji,
} from "../CustomStyleComponents/StyledComponents";
import Base from "../CustomStyleComponents/Base";
import { replaceEmojiWithLocalImg } from "../replaceEmojis";

interface ButtonOption {
  emoji: string;
}

interface SoundButtonsProps {
  text: string;
  options: ButtonOption[];
  answer: number | number[];
  onCorrectSelect: () => void;
}

const QuizButtons = (props: SoundButtonsProps) => {
  const [selected, setSelected] = React.useState(4);
  const [selectedColor, setSelectedColor] = React.useState("white");
  const [clicked, setClicked] = React.useState(false);

  // const onSelectCollection = (index: number) => {
  // setSelected(index);
  // setSelectedColor("purple");
  // props.onSelect();
  // };

  const checkAnswer = (selectedIndex: number) => {
    const answer = props.answer;
    if (Array.isArray(answer)) {
      const match = answer.indexOf(selectedIndex);
      return match;
    } else {
      return answer === selectedIndex ? answer : -1;
    }
  };

  React.useEffect(() => {
    setSelected(4);
    setSelectedColor("white");
    setClicked(false);
  }, [props.options]);

  return (
    <Base
      mainText={
        <div>
          <ButtonContainer>
            {props.options.map((option, index) => (
              <div
                style={{
                  backgroundColor: index === selected ? selectedColor : "white",
                  pointerEvents: clicked ? "none" : "auto",
                }}
                key={index}
                onClick={() => {
                  setSelected(index);
                  if (checkAnswer(index) === -1) {
                    //wrong
                    setSelectedColor("red");
                    // playWrong();
                  } else {
                    //right
                    setSelectedColor("limegreen");
                    // playCorrect();
                    setClicked(true);
                    props.onCorrectSelect();
                  }
                }}
              >
                {
                  <ReplacedEmoji size="6rem">
                    {replaceEmojiWithLocalImg(option.emoji)}
                  </ReplacedEmoji>
                }
              </div>
            ))}
          </ButtonContainer>
        </div>
      }
      footerText={<Text size="1.5rem">{props.text}</Text>}
    />
  );
};

export default QuizButtons;
