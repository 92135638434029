import styled from "styled-components";

export const Text = styled.h1<{ size?: string }>`
  font-family: "edmondsans-sq";
  font-size: ${(props) => props.size || "1.75rem"};
  padding: 0rem 1rem 0rem 1rem;
  > .emojified {
    width: ${(props) => props.size || "1.75em"};
  }
`;

export const TextBiggerEmoji = styled.h1<{ size?: string }>`
  font-family: "edmondsans-sq";
  font-size: ${(props) => props.size || "1.75rem"};
  padding: 0rem 1rem 0rem 1rem;
  > .emojified {
    width: 2.5em;
  }
`;

export const Emoji = styled.p<{ size?: string }>`
  font-size: ${(props) => props.size || "10rem"};
`;

// export const TextWithEmoji = styled.p<{ size?: string }>`
//   font-family: "edmondsans-sq";
//   font-size: ${(props) => props.size || "2rem"};

//   > .emojified {
//     width: 1.25em;
//     padding: 0 0.05em 0 0.1em;
//     vertical-align: -0.2em;
//   }
// `;

export const ReplacedEmoji = styled.p<{ size?: string }>`
  > .emojified {
    width: ${(props) => props.size || "10em"};
  }
`;

export const UserTextInput = styled.input<{ valid?: boolean }>`
  font-family: "edmondsans-sq";
  text-align: center;
  font-size: 25px;

  margin: 1.5em;

  border: 3.5px solid;
  border-radius: 15px;
  outline-color: ${(props) => (props.valid ? "black" : "red")};
`;

export const UserEmojiSelect = styled.select`
  font-family: "edmondsans-sq";
  text-align: center;
  text-align-last: center;
  font-size: 2.5rem;

  color: black;
  border: 2px solid black;
  border-radius: 10px;
  padding: 0.5rem;
  background: transparent;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  margin: 5px 0px 0px 0px;
  height: 100%;

  > div {
    font-size: 4rem;
    border-radius: 15px;
    border: 2px solid;
    color: #000;
    width: 40%;
    text-align: center;
    margin: 25px 0px 0px 0px;
    padding: 0.5rem;
  }
`;

export const HeartContainer = styled.div<{ size?: string }>`
  > .emojified {
    width: ${(props) => props.size || "3.75rem"};
  }
  display: flex;
  flex-direction: row;
  padding: 0rem 1rem 0rem 1rem;
`;
