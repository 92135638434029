import * as React from "react";
import StyledButton from "../CustomStyleComponents/Button";

interface QrStateButtonProps {
  qrState: boolean;
  setQRState: (state: boolean) => void;
}

const QRStateButton = (props: QrStateButtonProps) => {
  const isQrScanner = props.qrState;

  const buttonText = isQrScanner ? "Go back to QR Code" : "Activate scanner";
  const qrState = isQrScanner ? false : true;

  return (
    <StyledButton onClick={() => props.setQRState(qrState)}>
      {buttonText}
    </StyledButton>
  );
};

export default QRStateButton;
