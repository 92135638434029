import * as React from "react";
import { useInterpolatedString } from "./toucan/src/interpolate";
import { useTranslation } from "react-i18next";
import { playRandomNotificationSound } from "./sound";
import { Text } from "./CustomStyleComponents/StyledComponents";
import Base, { ISharedBaseProps } from "./CustomStyleComponents/Base";

interface SimpleTextProps extends ISharedBaseProps {
  text: string;
}

const SimpleText = (props: SimpleTextProps) => {
  const { t } = useTranslation("emojiii");
  const transText = t(props.text);
  const text = useInterpolatedString(transText);

  React.useEffect(() => {
    //prevent notification sample being triggered when there is a custom sample
    if (!props.sample) {
      playRandomNotificationSound();
    }
  }, [text]);

  return <Base {...props} mainText={<Text>{text}</Text>} />;
};

export default SimpleText;
