import * as React from "react";
import GenericLayout from "./toucan/src/components/GenericLayout";
import { useWriteOnlyFirebaseUserState } from "./toucan/src/firebase/hooks";
import Button from "react-bootstrap/Button";
import { useInterpolatedString } from "./toucan/src/interpolate";
import InterfaceManager from "./toucan/src/interfaces/manager";
import styled from "styled-components";

interface GateProps {
  url: string;
  buttonText: string;
  nextInterface: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 100%;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const ImageGate = (props: GateProps) => {
  const url = useInterpolatedString(props.url);
  const nextInterface = useInterpolatedString(props.nextInterface);
  const setInterface = useWriteOnlyFirebaseUserState<string>("interface");

  const onClick = () => {
    if (nextInterface) {
      setInterface("lekkerding");
    }
  };

  React.useEffect(() => {
    InterfaceManager.notifyComponentChanged("imageGate");
  }, [url]);

  if (!url) {
    return null;
  }
  return (
    <GenericLayout
      mainText={
        <div>
          <Container>
            <img src={url} alt="dynamic" />
            <Button variant="success" onClick={onClick}>
              {props.buttonText}
            </Button>
          </Container>
        </div>
      }
    />
  );
};

export default ImageGate;
