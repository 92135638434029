import * as React from "react";
import Button from "react-bootstrap/Button";

interface buttonProps {
  children: string | JSX.Element | JSX.Element[];
  onClick: () => void;
  disabled?: boolean;
}

const StyledButton = (props: buttonProps) => {
  return (
    <Button
      style={{ fontFamily: "edmondsans-sq", fontSize: "1.5rem" }}
      size="lg"
      variant="success"
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  );
};

export default StyledButton;
