import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import FirebaseImpl from "./toucan/src/firebase";
import { Tone } from "@smartphoneorchestra/live.js";

// import AudioManager from "./Managers/AudioManager";
import { registerInterfaces } from "./Managers/InterfaceManager";

import "./toucan/src/stylesheets/main.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { createGlobalStyle } from "styled-components";
import Loading from "./CustomStyleComponents/Loading";

import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-locize-backend";
import { initReactI18next } from "react-i18next";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/browser";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://800001814550473abd945f6b2d24d14a@o395783.ingest.sentry.io/4504277421916160",
    integrations: [new BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.25,
  });
}

i18next
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: "en",
    ns: ["toucan", "emojiii"],
    backend: {
      projectId: "1882e170-35f1-447f-9b1e-ebe5c9474b0b",
      referenceLng: "en",
    },
    detection: {
      order: ["localStorage", "navigator"],
      lookupLocalStorage: "emojiiiLng",
      caches: ["localStorage"],
      excludeCacheFor: ["cimode"],
    },
  });

FirebaseImpl.initialize({
  apiKey: "AIzaSyBbGX9daWf1FRKx8JLPrd8QyhpS0yxeGZE",
  databaseURL: "https://emojiii-4acc2-default-rtdb.firebaseio.com/",
  localStorageUUID: "emojiii",
});

//init all the managers
registerInterfaces();
// AudioManager.init();

Tone.Transport.PPQ = 48;

const GlobalStyle = createGlobalStyle`
  html,
  body {
    width: 100%;
    height: 100%;

		// make sure the user cannot scroll the background
		position: fixed;
  }

  #root {
		width: 100%;
		height: 100%;
	}
`;

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback={<Loading />}>
      <GlobalStyle />
      <App />
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
document
  .getElementsByTagName("body")[0]!
  .addEventListener("click", async () => {
    await Tone.start();
  });
