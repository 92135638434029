import * as React from "react";
import { useTranslation } from "react-i18next";
import Social from "./toucan/src/components/Social";
import GenericLayout from "./toucan/src/components/GenericLayout";
import { isAndroid, Onebang, randomElement, lowPass } from "./toucan/src/utils";
import { Tone } from "@smartphoneorchestra/live.js";
import { Text, ReplacedEmoji } from "./CustomStyleComponents/StyledComponents";
import { replaceEmojiWithLocalImg } from "./replaceEmojis";
import styled from "styled-components";

let currentValue = 0;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  margin: 0px 0px 0px 0px;
  height: 100%;
`;

const handclapPlayers = [
  new Tone.Player(
    "https://s3-eu-west-1.amazonaws.com/smartphone-orchestra/klappen/Klap_1-Lang.mp3"
  ).toDestination(),
  new Tone.Player(
    "https://s3-eu-west-1.amazonaws.com/smartphone-orchestra/klappen/Klap_2-Lang.mp3"
  ).toDestination(),
  new Tone.Player(
    "https://s3-eu-west-1.amazonaws.com/smartphone-orchestra/klappen/Klap_3-Lang.mp3"
  ).toDestination(),
  new Tone.Player(
    "https://s3-eu-west-1.amazonaws.com/smartphone-orchestra/klappen/Klap_4-Lang.mp3"
  ).toDestination(),
  new Tone.Player(
    "https://s3-eu-west-1.amazonaws.com/smartphone-orchestra/klappen/Klap_5-Lang.mp3"
  ).toDestination(),
  new Tone.Player(
    "https://s3-eu-west-1.amazonaws.com/smartphone-orchestra/klappen/Klap_6-Lang.mp3"
  ).toDestination(),
];

const playHandclampSound = () => {
  const randomPlayer = randomElement(handclapPlayers);
  randomPlayer.start();
};

const oneBang = new Onebang(playHandclampSound);

const deviceMotionEvent = (e: DeviceMotionEvent) => {
  if (e.acceleration) {
    e.preventDefault();
    // some devices don't have the necessary sensors and return null
    const x = Math.abs(e.acceleration.x || 0);
    currentValue = lowPass(x, currentValue);
    if (currentValue > 20) {
      oneBang.left();
    } else {
      oneBang.right();
    }
  }
};

const simpleEvent = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
  e.preventDefault();
  oneBang.left();
  oneBang.right();
};

const iPhoneClap = () => (
  <div onClick={simpleEvent}>
    <ReplacedEmoji size={"7rem"}>
      {replaceEmojiWithLocalImg("👏")}
    </ReplacedEmoji>
    <h1>Tap for a clap!</h1>
  </div>
);

const AndroidClap = (t: any) => <h1>{t("shake-your-phone")}</h1>;

const ClapEnded = () => {
  const { t } = useTranslation("toucan");

  React.useEffect(() => {
    window.addEventListener("devicemotion", deviceMotionEvent);
    return () => {
      window.removeEventListener("devicemotion", deviceMotionEvent);
    };
  }, []);

  const clapElement = isAndroid() ? AndroidClap(t) : iPhoneClap();
  return (
    <GenericLayout
      contentClass="text"
      mainText={clapElement}
      footerText={
        <Container>
          <Text style={{ margin: "0px 0px 25px 0px" }} size={"1.25rem"}>
            Follow Us!
          </Text>
          <Social />
        </Container>
      }
      onClick={simpleEvent}
    />
  );
};

export default ClapEnded;
