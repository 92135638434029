import * as React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import InterfaceManager from "./toucan/src/interfaces/manager";
// import * as Sound from "./sound";

interface VoteOption {
  text: string;
  color: string;
}

interface VoteButtonsProps {
  options: VoteOption[];
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;

  > div {
    border-radius: 5px;
    border: 1px solid #000;
    color: #000;
    width: 90%;
    text-align: center;
    padding: 1rem;
  }
`;

const VoteButtons = (props: VoteButtonsProps) => {
  const [vote, setVote] = React.useState("initial");
  const { t } = useTranslation("socialsorting");

  React.useEffect(() => {
    InterfaceManager.notifyComponentChanged("textslider");
  }, [props.options]);

  React.useEffect(() => {
    if (vote) {
      document.body.style.backgroundColor = vote;
    }
    return () => {
      document.body.style.backgroundColor = "initial";
    };
  }, [vote]);

  if (vote !== "initial") {
    return null;
  } else {
    return (
      <Container>
        {props.options.map((option) => (
          <div
            key={option.text}
            onClick={() => {
              setVote(option.color);
              // Sound.playRandomSound();
            }}
          >
            {t(option.text)}
          </div>
        ))}
      </Container>
    );
  }
};

export default VoteButtons;
