import { Tone } from "@smartphoneorchestra/live.js";

type fixedPlayersObj = Record<string, Tone.Player>;

const fixedPlayers: fixedPlayersObj = {
  welcome: new Tone.Player(
    "https://firebasestorage.googleapis.com/v0/b/emojiii-4acc2.appspot.com/o/samples%2FEpicSounds%2FEmoji_Arrived.mp3?alt=media&token=706a19e2-6664-403b-9c85-bcdb95a7b53f"
  ).toDestination(),
  emoji: new Tone.Player(
    "https://firebasestorage.googleapis.com/v0/b/emojiii-4acc2.appspot.com/o/samples%2FEpicSounds%2FEpic_Start_Louder.mp3?alt=media&token=4b6958b3-8d44-4c5a-85e5-1311db00e086"
  ).toDestination(),
  attention: new Tone.Player(
    "https://firebasestorage.googleapis.com/v0/b/emojiii-4acc2.appspot.com/o/samples%2FEpicSounds%2FChoose_Sting_3.mp3?alt=media&token=f3512f6f-88d9-42cd-b102-775f806deb5f"
  ).toDestination(),
  cheering: new Tone.Player(
    "https://firebasestorage.googleapis.com/v0/b/emojiii-4acc2.appspot.com/o/samples%2FEpicSounds%2FCrowd_Yeah.mp3?alt=media&token=87ef3aa5-63cb-4eb9-b4b2-af2b049dddd9&_gl=1*160z2ic*_ga*MTgwMzI2NDI3My4xNjI0OTczNzQx*_ga_CW55HF8NVT*MTY4NjE1MTcwNi43Ny4xLjE2ODYxNTI1MDYuMC4wLjA."
  ).toDestination(),
};

export default fixedPlayers;
