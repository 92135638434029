import * as React from "react";
import styled from "styled-components";
import { useInterpolatedString, useVariable } from "./toucan/src/interpolate";
import InterfaceManager from "./toucan/src/interfaces/manager";

interface ColorProps {
  color: string;
  showLogo?: boolean;
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Color = (props: ColorProps) => {
  const interpolatedColor = useInterpolatedString(props.color);
  const logoWhite = useVariable<boolean>(props.showLogo, false);

  React.useEffect(() => {
    InterfaceManager.notifyComponentChanged("color");
  }, [props.color, props.showLogo]);

  React.useEffect(() => {
    document.body.style.backgroundColor = interpolatedColor;
    return () => {
      document.body.style.backgroundColor = "initial";
    };
  }, [interpolatedColor, props.showLogo]);
  return <Container>{logoWhite && <div className={"logo"} />}</Container>;
};

export default Color;
