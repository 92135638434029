import * as React from "react";
import {
  useFirebaseUserState,
  useReadOnlyUserState,
} from "../toucan/src/firebase/hooks";
import { Update } from "../toucan/src/firebase/mutations";
import EmoText from "./EmoText";
import EmoButtons from "./EmoButtons";
import Loading from "../CustomStyleComponents/Loading";
import Base from "../CustomStyleComponents/Base";
import { useTranslation } from "react-i18next";
import { Text } from "../CustomStyleComponents/StyledComponents";

interface EmogameProps {
  countFaults: boolean;
  maxFaults: number;
  loserGate: string;
  winnerGate: string;
}

const gameStateSwitch = (currentState: string | number) => {
  const flippedState = currentState === "controller" ? "actor" : "controller";
  return flippedState;
};

const Emogame = (props: EmogameProps) => {
  const { t } = useTranslation("emojiii");

  const [userFaults, setUserFaults] = useFirebaseUserState<number>("faults");

  const [userEmojies] = useReadOnlyUserState<string[][]>("emojies");
  const [userAnswers] = useReadOnlyUserState<number[]>("answers");
  const [userGamestate] = useReadOnlyUserState<string>("gameState");
  const [userGameIndex] = useReadOnlyUserState<number>("gameIndex");
  const [otherId] = useReadOnlyUserState<string>("otherName");

  const gameIndex = userGameIndex || 0;
  const gameState = userGamestate || "";

  const loserGate = props.loserGate;
  const winnnerGate = props.winnerGate;
  const maxFaults = props.maxFaults;

  //for button on the wright awnser
  const onNext = async () => {
    const newGameIndex = gameIndex + 1;
    const ownGamestate = gameStateSwitch(gameState); //switch own game state
    const update = new Update();
    update.updateUser({
      gameIndex: newGameIndex,
      gameState: ownGamestate,
    });
    update.updateKey(`users/${otherId}`, {
      gameIndex: newGameIndex,
      gameState: gameState,
    });
    update.execute();
  };

  //when player makes a mistake
  const handleFaultOption = () => {
    if (props.countFaults) {
      if (userFaults && userFaults < maxFaults - 1) {
        const newFaults = userFaults + 1;
        setUserFaults(newFaults);
      } else if (!userFaults) {
        setUserFaults(1);
      } else {
        const update = new Update();
        const keysToUpdate = {
          interface: loserGate,
          gameState: "loser",
          emojies: [],
          answers: [],
        };
        setTimeout(() => {
          update.updateUser(keysToUpdate);
          update.updateKey(`users/${otherId}`, keysToUpdate);
          update.execute();
        }, 750);
      }
    }
  };

  const handleGameWon = () => {
    const update = new Update();
    update.updateUser({
      interface: winnnerGate,
      gameState: "winner",
      emojies: [],
      answers: [],
    });
    update.execute();
  };

  if (!Array.isArray(userEmojies) || userEmojies.length === 0 || !userAnswers) {
    return <Loading />;
  }
  if (gameIndex >= userEmojies.length) {
    handleGameWon();
    return <Loading />;
  }
  if (userGamestate === "controller") {
    return (
      <EmoButtons
        gameIndex={gameIndex}
        options={userEmojies}
        answers={userAnswers}
        onNext={onNext}
        handleFaultOption={handleFaultOption}
        faults={userFaults || 0}
        maxFaults={maxFaults || 5}
        showHearts={props.countFaults}
        otherId={otherId || ""}
      ></EmoButtons>
    );
  } else if (userGamestate === "actor") {
    return (
      <EmoText
        gameIndex={gameIndex}
        options={userEmojies}
        answers={userAnswers}
      ></EmoText>
    );
  } else if (userGamestate === "ended") {
    return <Base mainText={<Text>{"Sorry...time's up!"}</Text>}></Base>;
  } else if (userGamestate === "loading") {
    return <Loading />;
  }
  return <Base mainText={<Text>{t("game-error")}</Text>}></Base>;
};

export default Emogame;
