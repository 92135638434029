import * as React from "react";
import { useWriteOnlyFirebaseUserState } from "./toucan/src/firebase/hooks";
import { useInterpolatedString } from "./toucan/src/interpolate";
import { useTranslation } from "react-i18next";
import InterfaceManager from "./toucan/src/interfaces/manager";
import { Text } from "./CustomStyleComponents/StyledComponents";
import StyledButton from "./CustomStyleComponents/Button";
import Base from "./CustomStyleComponents/Base";
import { useReplaceEmojis } from "./replaceEmojis";

interface GateProps {
  text: string;
  buttonText: string;
  nextInterface: string;
}

const Gate = (props: GateProps) => {
  const { t } = useTranslation("emojiii");
  const transtext = t(props.text);
  const transButtontext = t(props.buttonText);
  const text = useInterpolatedString(transtext);

  const replacedText = useReplaceEmojis(text);
  const replacedButtonTexted = useReplaceEmojis(transButtontext);

  const characterCount = text.length;

  const nextInterface = useInterpolatedString(props.nextInterface);
  const setInterface = useWriteOnlyFirebaseUserState<string>("interface");

  const onClick = () => {
    if (nextInterface) {
      setInterface(nextInterface);
    }
  };

  React.useEffect(() => {
    InterfaceManager.notifyComponentChanged("gate");
  }, [text]);

  return (
    <Base
      showLogo={characterCount > 125 ? false : true}
      mainText={<Text>{replacedText}</Text>}
      footerText={
        <StyledButton onClick={onClick}>{replacedButtonTexted}</StyledButton>
      }
    />
  );
};

export default Gate;
