import * as React from "react";
import { Tone } from "@smartphoneorchestra/live.js";
import fixedPlayers from "./fixedPlayers";

// import AudioManager from "../Managers/AudioManager";

export function useSample(sampleId?: string) {
  React.useEffect(() => {
    if (!sampleId) {
      return;
    }

    const player = fixedPlayers[sampleId];
    if (!player) {
      return;
    }

    if (player.buffer.loaded) {
      player.start(Tone.now());
    } else {
      // player.autostart = true;
      console.log(`Buffer not loaded for sample: ${sampleId}`);
    }

    return () => {
      // if (player.state === "started") {
      //   player.dispose();
      // }
      player.stop();
    };
  }, [sampleId]);
}

// export function oneShotSample(url?: string) {
//   if (!url) {
//     return;
//   }

//   const buffer = AudioManager.getBuffer(url);
//   if (!buffer) {
//     return;
//   }

//   const player = new Tone.Player(buffer).toDestination();
//   if (buffer.loaded) {
//     player.start(Tone.now());
//   } else {
//     player.autostart = true;
//   }
// }
