import { replaceEmojiWithLocalImg } from "../replaceEmojis";

const Hearts = (faults: number, hearts: number) => {
  const life = replaceEmojiWithLocalImg("❤️");
  const broken = replaceEmojiWithLocalImg("💔");

  const tresh = hearts - faults;

  const lifeArr = new Array(hearts).fill(life);
  const collection = lifeArr.map((slot, index) => {
    const heart = index < tresh ? slot : broken;
    return heart;
  });

  return collection;
};

export default Hearts;
