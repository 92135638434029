import * as React from "react";
import { useWriteOnlyFirebaseUserState } from "./toucan/src/firebase/hooks";
import { useInterpolatedString } from "./toucan/src/interpolate";
import { playDataSorting, stopDataSorting } from "./sound";

import { useTranslation } from "react-i18next";
import { Text, ReplacedEmoji } from "./CustomStyleComponents/StyledComponents";
import Base, { ISharedBaseProps } from "./CustomStyleComponents/Base";
import StyledButton from "./CustomStyleComponents/Button";
import { replaceEmojiWithLocalImg, useReplaceEmojis } from "./replaceEmojis";

interface EmojiGateProps extends ISharedBaseProps {
  findPartnerEmoji: string;
  buttonText: string;
  nextInterface: string;
  text: string;
}

const FindEmojiGate = (props: EmojiGateProps) => {
  const { t } = useTranslation("emojiii");
  const nextInterface = useInterpolatedString(props.nextInterface);
  const emoticon = useInterpolatedString(props.findPartnerEmoji);
  const emoticonTrans = t(emoticon);
  const replacedEmoji = replaceEmojiWithLocalImg(emoticonTrans);
  const transText = t(props.text);
  const transButtonText = t(props.buttonText);
  const replacedText = useReplaceEmojis(transText);

  const setInterface = useWriteOnlyFirebaseUserState<string>("interface");
  const onClick = () => {
    if (nextInterface) {
      setInterface(nextInterface);
    }
  };

  React.useEffect(() => {
    playDataSorting();

    return function cleanup() {
      stopDataSorting();
    };
  }, []);

  return (
    <Base
      {...props}
      mainText={
        <div>
          <Text size="1.5rem">{replacedText}</Text>
          <ReplacedEmoji size="7.5rem">{replacedEmoji}</ReplacedEmoji>
        </div>
      }
      footerText={
        <StyledButton onClick={onClick}>{transButtonText}</StyledButton>
      }
    />
  );
};

export default FindEmojiGate;
