import * as React from "react";
import { useTranslation } from "react-i18next";
import { playRandomNotificationSound } from "../sound";
import Base from "../CustomStyleComponents/Base";
import { Text } from "../CustomStyleComponents/StyledComponents";
import StyledButton from "../CustomStyleComponents/Button";
import styled from "styled-components";
import { useReplaceEmojis } from "../replaceEmojis";
import { playCustomSample } from "../sound";

const texts = [
  "welcome",
  "unmute-loud",
  "keep-awake",
  "refresh",
  "help-desk",
  "check-phone",
  "plural",
  "im-ready",
];

const buttonTexts = [
  "lets",
  "its-up",
  "tapped",
  "clear",
  "comforting",
  "promise",
  "fish",
  " ",
];

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  margin: 5px 0px 0px 0px;
  height: 100%;
`;

const OnBoarding = () => {
  const { t } = useTranslation("emojiii");
  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    const lastSlideIndex = texts.length - 1;
    if (index !== lastSlideIndex) {
      playRandomNotificationSound();
    }
    if (index === lastSlideIndex) {
      playCustomSample(
        "https://firebasestorage.googleapis.com/v0/b/emojiii-4acc2.appspot.com/o/samples%2FEpicSounds%2FEmoji_Arrived.mp3?alt=media&token=706a19e2-6664-403b-9c85-bcdb95a7b53f"
      );
    }
  }, [index]);

  return (
    <Base
      showLogo={false}
      mainText={
        <Container>
          <Text size="1rem" style={{ color: "green" }}>
            {index + 1} of {texts.length}
          </Text>
          <Text size={"1.5rem"}>{useReplaceEmojis(t(texts[index]))}</Text>
        </Container>
      }
      footerText={
        index < texts.length - 1 ? (
          <StyledButton onClick={() => setIndex((index + 1) % texts.length)}>
            {t(buttonTexts[index])}
          </StyledButton>
        ) : undefined
      }
    />
  );
};

export default OnBoarding;
