import InterfaceManager from "../toucan/src/interfaces/manager";
import { useFirebaseUserState } from "../toucan/src/firebase/hooks";

import Base from "../CustomStyleComponents/Base";
import Action from "../Action";
import Questions from "../Questions";
import Color from "../Color";
import Image from "../Image";
import VoteButtons from "../VoteButtons";
import TextSlider from "../TextSlider";
import Gate from "../Gate";
import Number from "../Number";
import EmoticonText from "../EmoticonText";
import ImageGate from "../ImageGate";
import EmojiGate from "../EmojiGate";
import EmojiPicker from "../EmojiPicker";
import QrCode from "../QrCode";
import SoundButtonsGate from "../SoundButtons";
import Emogame from "../EmoGame";
import SimpleText from "../SimpleText";
import LoserGate from "../LoserGate";
import QuizButtonsGate from "../QuizButtons";
import ButtonGate from "../ButtonGate";
import FindEmojiGate from "../FindEmojiGate";
import EmoticonTextFetch from "../EmoticonTextFetch";
import ClapEnded from "../ClapEnded";

import { playRandomNotificationSound } from "../sound";

export function useInterfaceManager() {
  const [userInterface] = useFirebaseUserState<string>(`interface`);

  return userInterface;
}

export const registerInterfaces = () => {
  InterfaceManager.registerInterface("base", Base);
  InterfaceManager.registerInterface("action", Action);
  InterfaceManager.registerInterface("questions", Questions);
  InterfaceManager.registerInterface("color", Color);
  InterfaceManager.registerInterface("image", Image);
  InterfaceManager.registerInterface("votebuttons", VoteButtons);
  InterfaceManager.registerInterface("textslider", TextSlider);
  InterfaceManager.registerInterface("gate", Gate);
  InterfaceManager.registerInterface("number", Number);
  InterfaceManager.registerInterface("emoticontext", EmoticonText);
  InterfaceManager.registerInterface("imagegate", ImageGate);
  InterfaceManager.registerInterface("emojigate", EmojiGate);
  InterfaceManager.registerInterface("emojipicker", EmojiPicker);
  InterfaceManager.registerInterface("qrcode", QrCode);
  InterfaceManager.registerInterface("soundbuttons", SoundButtonsGate);
  InterfaceManager.registerInterface("emogame", Emogame);
  InterfaceManager.registerInterface("simpletext", SimpleText);
  InterfaceManager.registerInterface("losergate", LoserGate);
  InterfaceManager.registerInterface("quizbuttons", QuizButtonsGate);
  InterfaceManager.registerInterface("buttongate", ButtonGate);
  InterfaceManager.registerInterface("findemojigate", FindEmojiGate);
  InterfaceManager.registerInterface("emoticontextfetch", EmoticonTextFetch);
  InterfaceManager.registerInterface("customclapended", ClapEnded);

  InterfaceManager.setOnComponentChangedCallback(() => {
    playRandomNotificationSound();
  });
};
