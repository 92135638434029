import * as React from "react";
import * as Types from "../types";
import { Button } from "./Elements";

interface OptionProps {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onClick: (value: Types.Option) => void;
  option: Types.Option;
}

const Option = ({ option, onClick }: OptionProps) => (
  <Button onClick={() => onClick(option)}>{option.text}</Button>
);

export default Option;
