import * as React from "react";
import styled from "styled-components";
import Logo from "../toucan/src/images/Logo.svg";
import { useVariable } from "../toucan/src/interpolate";
import { useSample } from "../Sound/sample";

export interface ISharedBaseProps {
  sample?: string;
  style?: React.CSSProperties;
}

interface IBaseProps extends ISharedBaseProps {
  showLogo?: boolean;
  mainText?: string | React.ReactChild;
  footerText?: string | React.ReactChild;
}

//hier kunnen we nog altijd van alles zetten zoals in de originele logo in base
const logoStyle = {
  backgroundImage: `url(${Logo})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "top",
  backgroundSize: "50%",
};

const widowInnerHeight = window.innerHeight;
const smallScreenTresh = 680;
const scaleFactor = widowInnerHeight < smallScreenTresh ? 0.8 : 1;
const widthPercentage = widowInnerHeight < smallScreenTresh ? "100%" : "90%";
const justifySetting =
  widowInnerHeight < smallScreenTresh ? "space-evenly" : "space-evenly";

const Container = styled.div<{ footer?: boolean }>`
  // height: 100vh;
  height: ${widowInnerHeight}px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    color: #000;
    width: ${widthPercentage};
    height: 100%;
    text-align: center;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  > .logo {
    width: 50%;
    height: 10%;
    min-height: 10%;
    margin: 10px 0px 0px 0px;
    z-index: 1;
  }

  > .middle {
    height: ${(props) => (props.footer ? "50%" : "90")};
    justify-content: ${justifySetting};
    align-content: center;
    white-space: pre-wrap;
    z-index: 0;
    margin: 5px 0px 0px 0px;
    transform: scale(${scaleFactor}, ${scaleFactor});
  }

  > .bottom {
    height: 40%;
    white-space: pre-wrap;
    z-index: 1;
    transform: scale(${scaleFactor}, ${scaleFactor});
  }
`;

const Base = (props: IBaseProps) => {
  const showLogo = useVariable<boolean>(props.showLogo, true);
  useSample(props.sample);

  return (
    <Container style={props.style}>
      {showLogo && <div className="logo" style={{ ...logoStyle }}></div>}
      <div className="middle">{props.mainText}</div>
      {props.footerText ? (
        <div className="bottom">{props.footerText}</div>
      ) : null}
    </Container>
  );
};

export default Base;
