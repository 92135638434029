import * as React from "react";
import { useWriteOnlyFirebaseUserState } from "./toucan/src/firebase/hooks";
import { useInterpolatedString } from "./toucan/src/interpolate";
import { useTranslation } from "react-i18next";
import { playCustomSample } from "./sound";
import { Text } from "./CustomStyleComponents/StyledComponents";
import Base from "./CustomStyleComponents/Base";
import StyledButton from "./CustomStyleComponents/Button";
import { useReplaceEmojis } from "./replaceEmojis";
import InterfaceManager from "./toucan/src/interfaces/manager";

interface LoserGateProps {
  text: string;
  sample: string;
  buttonText: string;
  nextInterface: string;
}

const LoserGate = (props: LoserGateProps) => {
  const { t } = useTranslation("emojiii");
  const transText = t(props.text);
  const transButtontext = t(props.buttonText);
  const text = useInterpolatedString(transText);
  const replacedText = useReplaceEmojis(text);

  const nextInterface = useInterpolatedString(props.nextInterface);
  const setInterface = useWriteOnlyFirebaseUserState<string>("interface");

  const characterCount = text.length;

  const sample = props.sample;

  const onClick = () => {
    if (nextInterface) {
      setInterface(nextInterface);
    }
  };

  React.useEffect(() => {
    playCustomSample(sample);
    InterfaceManager.notifyComponentChanged("losergate");
  }, [text]);

  return (
    <Base
      showLogo={characterCount > 125 ? false : true}
      mainText={<Text>{replacedText}</Text>}
      footerText={
        <StyledButton onClick={onClick}>{transButtontext}</StyledButton>
      }
    />
  );
};

export default LoserGate;
