import * as React from "react";
import { useTranslation } from "react-i18next";
import { useInterpolatedString } from "../toucan/src/interpolate";
import { playRandomNotificationSound } from "../sound";
import { useWriteOnlyFirebaseUserState } from "../toucan/src/firebase/hooks";
import QuizButtons from "./QuizButtons";

interface ButtonOption {
  emoji: string;
}

interface QuizButtonsGateProps {
  question: string;
  emojiOptions: ButtonOption[];
  nextInterface: string;
  answerIndex: number | number[];
}

const QuizButtonsGate = (props: QuizButtonsGateProps) => {
  const setInterface = useWriteOnlyFirebaseUserState<string>("interface");

  const { t } = useTranslation("emojiii");
  const transQuestion = t(props.question);
  const question = useInterpolatedString(transQuestion);

  const buttonOptions = props.emojiOptions;
  const nextInterface = useInterpolatedString(props.nextInterface);

  const onSelect = () => {
    setTimeout(() => {
      setInterface(nextInterface);
    }, 1000);
  };

  React.useEffect(() => {
    playRandomNotificationSound();
  }, []);

  return (
    <QuizButtons
      text={question}
      options={buttonOptions}
      onCorrectSelect={onSelect}
      answer={props.answerIndex}
    ></QuizButtons>
  );
};

export default QuizButtonsGate;
